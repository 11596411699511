import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import {
  ActionTextField,
  AddressTextField,
  GOOGLE_MAPS_API_KEY,
  TrackedButton,
} from '@flock/shared-ui'
import {
  AddressData,
  createLeadHelper,
  localStore,
  UserEventType,
} from '@flock/utils'
import {
  Core_TransactionType,
  LandingCreateLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { navigate } from 'gatsby'
import { useMutation } from '@apollo/client'
import SectionLayout from '../SharedComponents/SectionLayout'
import ScheduleCall from '../SharedComponents/ScheduleCall'
import { useCalendly } from '../utils'
import { CALENDLY_LANDING_URL, DENVER_ADDRESS } from '../../constants'
import { ONBOARDING_PATH } from '../../routeConstants'
import { getEventTokenUUID, track } from '../../utils/analytics'
import { WebinarModal } from '../SharedComponents/WebinarModal'

const BlogHero = () => {
  const [addressData, setAddressData] = useState<AddressData | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [createLead] = useMutation(LandingCreateLeadDocument)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'blog',
    scheduleUrl: CALENDLY_LANDING_URL as string,
  })

  const isSSR = typeof window === 'undefined'

  const onSubmit = async () => {
    setLoading(true)
    track('blog-hero-cta-click', {
      type: 'button',
      leadConversion: 'started',
      actionType: UserEventType.BUTTON_CLICK,
    })
    const { search } = window.location
    const params = new URLSearchParams(search)
    if (addressData?.streetAddress) {
      try {
        const leadData = {
          addressData,
          source: 'onboarding',
          transactionType: Core_TransactionType.TransactionTypeSingle_721,
          eventTokenUuid: getEventTokenUUID(),
        }
        const queryParams = localStore?.getItem('queryParams')
        let parsedQueryParams: any = {}
        if (queryParams) {
          parsedQueryParams = JSON.parse(queryParams)
        }
        const createLeadResponse = await createLeadHelper(
          leadData,
          parsedQueryParams,
          createLead
        )
        const newLeadUuid = createLeadResponse?.data.createLead.lead.uuid
        let path = `${ONBOARDING_PATH}?leadUuid=${newLeadUuid}`
        if (typeof Storage !== 'undefined') {
          localStore?.removeItem('step')
          localStore?.removeItem('flowData')
        }
        // carry forward all url params to be parsed in the same flow as onboarding flow
        params.forEach((key, value) => {
          path += `&${value}=${key}`
        })
        navigate(path)
      } catch (e) {
        window.location.href = `https://form.jotform.com/tech700/flock-onboarding?address[addr_line1]=${addressData?.streetNumber}%20${addressData?.streetAddress}&address[city]=${addressData?.city}&address[state]=${addressData?.state}&address[postal]=${addressData?.zipcode}`
      }
    }
    setLoading(false)
  }

  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      onSubmit()
    }
  }

  return (
    <SectionLayout name="hero" backgroundColor="trustBlue.main">
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        sx={{ gap: { md: '32px', sm: '32px', xs: '16px' } }}
        align-items="center"
      >
        <Typography variant="h1" textAlign="center">
          The Flock Blog
        </Typography>
        <Typography variant="h4" textAlign="center">
          Confused about real estate investing? Read articles written by our
          real estate experts.
        </Typography>
        <ScheduleCall {...otherCalendlyProps} />
        {isMobile ? (
          <TrackedButton
            sx={{
              paddingLeft: { xs: '16px', sm: '24px', md: '24px' },
              paddingRight: { xs: '16px', sm: '24px', md: '24px' },
            }}
            onClick={() => navigate(ONBOARDING_PATH)}
            trackingConfig={{
              name: `blog-hero`,
              properties: {
                leadConversion: 'started',
                actionType: UserEventType.BUTTON_CLICK,
              },
            }}
          >
            Get Started
          </TrackedButton>
        ) : (
          <Box alignSelf="center" width={isTablet ? '100%' : '50%'}>
            {!isSSR && (
              <React.Suspense
                fallback={
                  <Grid item sm={12}>
                    <ActionTextField
                      size="large"
                      variant="outlined"
                      label="Rental property address"
                      placeholder={DENVER_ADDRESS}
                      fullWidth
                      onSubmit={() => {}}
                    />
                  </Grid>
                }
              >
                <Grid item sm={12}>
                  <AddressTextField
                    size="large"
                    variant="outlined"
                    label="Your rental property address"
                    placeholder={DENVER_ADDRESS}
                    ctaContent={
                      loading ? (
                        <Box
                          sx={{
                            width: {
                              md: '116px',
                              xs: '93px',
                            },
                          }}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CircularProgress size="24px" color="white" />
                        </Box>
                      ) : (
                        'Get an offer'
                      )
                    }
                    onKeyDown={onKeyDown}
                    fullWidth
                    onChange={(data: AddressData | null) =>
                      setAddressData(data!)
                    }
                    onSubmit={onSubmit}
                    googleMapsApiKey={GOOGLE_MAPS_API_KEY || ''}
                  />
                </Grid>
              </React.Suspense>
            )}
          </Box>
        )}
        <Grid item xs={12} textAlign="center">
          <WebinarModal />
        </Grid>
      </Grid>
    </SectionLayout>
  )
}

export default BlogHero
